import {acceptHMRUpdate, defineStore} from "pinia";
import {useSearch} from "~/store/search";
import {useClinics} from "~/store/clinics";
import {useRegion} from "~/store/app/region";
import {useApi} from "~/store/app/api";

import qs from 'qs';

// dynamic avatars
import photoManDefault_1 from "@/assets/img/docs/placeholders/man_1.png";
import photoManDefault_2 from "@/assets/img/docs/placeholders/man_2.png";
import photoManDefault_3 from "@/assets/img/docs/placeholders/man_3.png";
import photoManDefault_4 from "@/assets/img/docs/placeholders/man_4.png";
import photoWomanDefault_1 from "@/assets/img/docs/placeholders/woman_1.png";
import photoWomanDefault_2 from "@/assets/img/docs/placeholders/woman_2.png";
import photoWomanDefault_3 from "@/assets/img/docs/placeholders/woman_3.png";
import photoWomanDefault_4 from "@/assets/img/docs/placeholders/woman_4.png";
import {generateTextAge} from "~/libs/ui";


export const useDocs = defineStore("docs", {
    state: () => {
        const config = useRuntimeConfig();


        return {
            apiBase: config.public.apiBase,
            cache: config.public.cache,
            debug: config.STAGE === 'local',
            listDocs: [],
            docsMis: [],
            listSpecials: [],
            textSearch: "",
            avatarPlaceholders: {
                photoManDefault_1,
                photoManDefault_2,
                photoManDefault_3,
                photoManDefault_4,
                photoWomanDefault_1,
                photoWomanDefault_2,
                photoWomanDefault_3,
                photoWomanDefault_4,
            },
        };
    },

    getters: {
        getDocById: (state) => (docId) => {
            const doc = state.listDocs.find((item) => +item.id === +docId);

            if (!doc) {
                return null;
            }

            const name = `${doc.attributes.lname} ${doc.attributes.fname}`;
            const age = generateTextAge(doc.attributes.age_from, doc.attributes.age_to);
            const special = `${doc?.attributes?.special?.length ? doc.attributes.special + '. ' : ''}${age}`;
            let photoMiniSrc = '';
            if (doc?.attributes?.photo_mini?.data?.attributes?.url)
                photoMiniSrc = `https://admin.fomin-kids.ru${doc.attributes.photo_mini.data.attributes.url}`;

            return {
                ...doc,
                name,
                special,
                photoMiniSrc
            }
        },
        getDocByMisId: (state) => (docId) => {
            const doc = state.listDocs.find((doc) => {
                if (doc?.attributes?.DocId) {
                    return doc.attributes.DocId.some(combo => combo.docId === docId);
                }
            });

            if (!doc) {
                return null;
            }

            const {lname, fname, photo_mini} = doc.attributes;
            const name = `${lname} ${fname}`;
            const age = generateTextAge(doc.attributes.age_from, doc.attributes.age_to);
            const special = `${doc.attributes.special.length ? doc.attributes.special + '. ' : ''}${age}`;
            const photoMiniSrc = photo_mini?.data?.attributes?.url ? `https://admin.fomin-kids.ru${photo_mini.data.attributes.url}` : '';

            return {
                ...doc,
                name,
                special,
                photoMiniSrc
            };
        },
        getDocByLink: (state) => (link) =>
            state.listDocs.find((item) => item.attributes.link === link),
        getPhotoMini: (state) => (docId) => {
            const doc = state.listDocs.find((item) => item.id === docId);
            if (doc?.attributes.photo_mini?.data?.attributes?.url) {
                return `${state.apiBase}${doc?.attributes.photo_mini.data.attributes.url}`;
            }
        },
        getPhoto: (state) => (docId) => {
            const doc = state.listDocs.find((item) => item.id === docId);
            if (doc?.attributes.genPhoto?.data?.attributes?.url) {
                return `${state.apiBase}${doc?.attributes.genPhoto.data.attributes.url}`;
            } else if (doc?.attributes.photo?.data?.attributes?.url) {
                return `${state.apiBase}${doc?.attributes.photo.data.attributes.url}`;
            }
        },
        getPhotoPlaceholder: (state) => (doc) => {
            if (!doc) return;
            let {gender, lname, fname} = doc?.attributes;
            if (!gender) {
                gender = "woman";
            }

            const name = `${lname} ${fname}`;
            const num = (name?.length % 4) + 1;
            const capitalizeGender =
                gender[0].toUpperCase() + gender.slice(1, Infinity);

            const photoKey = `photo${capitalizeGender}Default_${num}`;

            return state.avatarPlaceholders[photoKey];
        },
        getDocMisId: (state) => (docId, clinicId) => {
            const doc = state.getDocById(docId);
            const combo = doc.attributes.DocId.find(combo => combo.clinic.data.attributes.clinicId === clinicId);
            return combo.docId;
        },
        docsByClinic: (state) => {
            const clinicStore = useClinics();
            const clinicId = clinicStore.clinicId;

            return state.listDocs.filter((doc) => {
                if (
                    doc.attributes.clinics.data.findIndex(
                        (clinic) => clinic.attributes.clinicId === clinicId
                    ) !== -1
                ) {
                    return true;
                }
            });
        },
        docsByRegion: (state) => {
            const regionStore = useRegion();

            return state.listDocs.filter((docId) => {
                const doc = state.getDocById(docId.id);

                let res = false
                if (doc.attributes.DocId.length) {
                    doc.attributes.DocId.forEach((item) => {
                        if (item?.clinic?.data?.attributes?.clinicId && regionStore.listClinicsRegion().includes(item.clinic.data.attributes.clinicId)) {
                            res = true
                        }
                    })
                }
                return res
            })
        },
        filterListDocs: (state) => (limit = null) => {
            const searchStore = useSearch();
            const regionStore = useRegion();

            return state.listDocs
                .filter((docId) => {
                    const doc = state.getDocById(docId.id);
                    if (doc.attributes.hide) return false;

                    let res = false
                    if (doc.attributes.DocId.length) {
                        doc.attributes.DocId.forEach((item) => {
                            if (searchStore?.searchClinic?.id !== null && searchStore.searchClinic !== null) {
                                if (item?.clinic?.data?.attributes?.clinicId && item.clinic.data.attributes.clinicId === searchStore.searchClinic.attributes.clinicId) {
                                    res = true
                                }
                            } else if (item?.clinic?.data?.attributes?.clinicId && regionStore.listClinicsRegion().includes(item.clinic.data.attributes.clinicId)) {
                                res = true
                            }
                        })
                    }
                    return res
                })
                .filter((docId) => {
                    const doc = state.getDocById(docId.id);
                    let res = false;

                    if (searchStore?.searchSpecial?.id !== null && searchStore?.searchSpecial !== null && searchStore?.searchSpecial?.id !== undefined) {
                        res = doc.attributes.specials?.data.some(special => {
                            return special.id === searchStore.searchSpecial.id
                        });
                    } else {
                        res = true;
                    }

                    return res;
                })
                .filter((doc) => {
                    if (searchStore.searchText.length < 3) return true;
                    if (
                        doc.attributes.lname
                            .toLowerCase()
                            .indexOf(searchStore.searchText.toLowerCase().trim()) !== -1
                    ) {
                        return true;
                    }
                    if (
                        doc.attributes.fname
                            .toLowerCase()
                            .indexOf(searchStore.searchText.toLowerCase().trim()) !== -1
                    ) {
                        return true;
                    }
                })
                .sort((a, b) => {
                    if (a.attributes.lname === "Все специализации") return -1;
                    if (b.attributes.lname === "Все специализации") return 1;
                    if (a.attributes.lname > b.attributes.lname) return 1;
                    if (a.attributes.lname < b.attributes.lname) return -1;
                    return 0;
                })
                .filter((doc, idx) => {
                    if (limit !== null) return idx < limit; else return true
                });
        },
        filterByAge: (state) => (docsList, age) => {
            const isDoctorAgeInRange = (docId, age) => {
                const doc = state.getDocById(docId);
                const age_from = doc.attributes.age_from ?? 0;
                const age_to = doc.attributes.age_to ?? +Infinity;

                return age_from !== 0 || age_to !== +Infinity ? age_from <= age && age <= age_to : false;
            };

            return docsList.filter(docId => isDoctorAgeInRange(docId, age));
        },
        filterByOnline: (state) => (docsList, online = false) => {
            const isDoctorOnline = (docId) => {
                const doc = state.getDocById(docId);
                return doc.attributes.online === true;
            };

            if (!online) {
                return docsList;
            }

            return docsList.filter(docId => isDoctorOnline(docId));
        },
        getDocName: (state) => (doc) => {
            if (doc) {
                return `${doc.attributes.lname} ${doc.attributes.fname} ${doc.attributes.pname}`;
            }
        },
        createApiUrl: (state) => (url) => {
            return `${state.apiBase}${url}`;
            // return `${state.apiBase}${url}${
            //     state.cache ? "" : "&" + new Date().getTime()
            // }`;
        },
    },

    actions: {
        async fetchDocs() {
            const apiStore = useApi();
            let populate = {
                clinics: '*',
                specials: '*',
                book: {
                    populate: {
                        pricetime: {
                            populate: '*',
                        },
                        clinic: {
                            fields: ['clinicId']
                        }
                    }
                },
                DocId: {
                    populate: {
                        clinic: {
                            fields: ['clinicId']
                        }
                    }
                },
                homeSlots: {
                    populate: {
                        clinic: {
                            fields: ['clinicId']
                        }
                    }
                },
                photo_mini: '*',
                photo: '*',
                genPhoto: '*',
            };

            const cache = '&cache=get';
            const query = qs.stringify({populate, pagination: {pageSize: 1000}, sort: 'lname'}, {encode: true});
            const url = `/api/docs?${query}${cache}`;
            this.listDocs = (await apiStore.fetchData(url));
        },
        async fetchDocByLink(link, extended = false) {
            const filters = {
                link: {
                    $eq: link,
                },
            };
            let populate = '*';
            if (extended) {
                populate = {

                    book: {
                        populate: {
                            pricetime: {
                                populate: '*',
                            },
                            clinic: {
                                populate: {
                                    price: {
                                        populate: '*',
                                    }
                                }
                            }
                        }
                    },
                    tabs: {
                        populate: {
                            Tab: {
                                populate: '*',
                            },
                        },
                    },
                    skills: {
                        populate: {
                            Service: {
                                populate: '*'
                            },
                        },
                    },
                    photo: '*',
                    genPhoto: '*',
                    gallery: '*',
                    docinfo: {
                        populate: {
                            doc: {
                                populate: '*',
                            },
                            Doc: {
                                populate: '*',
                            },
                            Price: {
                                populate: '*',
                            },
                            image: {
                                populate: '*',
                            },
                            faq: {
                                populate: '*',
                            },
                            Tab: {
                                populate: '*'
                            },
                            Reason: {
                                populate: '*'
                            },
                            Review: {
                                populate: '*'
                            },
                            text: {
                                populate: '*'
                            }
                        },
                    },
                    banners: {
                        populate: {
                            image_mobile: {
                                populate: '*'
                            },
                            image_desktop: {
                                populate: '*'
                            }
                        }
                    },
                    SEO: {
                        populate: '*'
                    }
                };
            }

            const query = qs.stringify({filters, populate}, {encode: true});
            const url = `/api/docs?${query}`;
            console.log(url)

            const startTime = performance.now();
            const docs = await fetch(
                `${this.apiBase}${url}`
            )
                .then((response) => response.json())
                .then((result) => result.data)
                .catch((error) => console.log("error", error));
            const endTime = performance.now();
            const durationInSeconds = ((endTime - startTime) / 1000).toFixed(3);
            if (this.debug)
                console.log(`${durationInSeconds} - ${url}`);

            if (Object.values(docs).length) {
                return docs[0];
            } else {
                console.log("Service is empty");
            }
        },
        async fetchDoc(docId) {
            const startTime = performance.now();
            const url = `/api/docs/${docId}?populate=deep,2`;
            const doc = await fetch(
                this.createApiUrl(url)
            )
                .then((response) => response.json())
                .then((result) => result.data)
                .catch((error) => console.log("error", error));
            const endTime = performance.now();
            const durationInSeconds = ((endTime - startTime) / 1000).toFixed(3);
            if (this.debug)
                console.log(`${durationInSeconds} - ${url}`);
            if (Object.values(doc).length) {
                // doc.attributes.online = false;
                return doc;
            } else {
                console.log("Doc is empty");
            }
        },
        async fetchDocsMis(clinicId) {
            const docsMis = await fetch(
                this.createApiUrl(`/api/mis/docs?clinicId=${clinicId}`)
            )
                .then((response) => response.json())
                .then((result) => result)
                .catch((error) => console.log("error", error));
            if (Array.isArray(docsMis)) {
                this.docsMis = [...docsMis];
            } else if (docsMis) {
                this.docsMis = [docsMis];
            } else {
                console.log("DocMis is empty");
            }
        },
        async fetchSpecials() {
            const url = `/api/specials?populate=deep,2`;
            const startTime = performance.now();
            const listSpecials = await fetch(
                this.createApiUrl(url)
            )
                .then((response) => response.json())
                .then((result) => result.data)
                .catch((error) => console.log("error", error));
            const endTime = performance.now();
            const durationInSeconds = ((endTime - startTime) / 1000).toFixed(3);
            if (this.debug)
                console.log(`${durationInSeconds} - ${url}`);

            if (listSpecials?.length) {
                function compare(a, b) {
                    if (a.attributes.title < b.attributes.title) {
                        return -1;
                    }
                    if (a.attributes.title > b.attributes.title) {
                        return 1;
                    }
                    return 0;
                }

                listSpecials.sort(compare);

                this.listSpecials = [
                    {id: null, attributes: {title: "Все специализации"}},
                    ...listSpecials,
                ];
            } else {
                console.log("List specials is empty");
            }
        },
        addDocScore(id) {
            let docIdx = this.listDocs.findIndex((item) => item.id === id);
            if (this.listDocs[docIdx].score) {
                this.listDocs[docIdx].score += 300;
            } else {
                this.listDocs[docIdx].score = 300;
            }
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useDocs, import.meta.hot));
}
